import React from 'react';

import { useEffect, useState } from "react";
//API Middle man
import useApi from "../utils/UseAPI";
// React loading spinner
import Spinner from 'react-bootstrap/Spinner';

import SanitizeHref from "../utils/SanitizeHref";
import { Link } from 'react-router-dom';

function EmergencyContact() {
    const [links, setLinks] = useState([]);
    const { error, processing, getRequest } = useApi();

    useEffect(() => {

        const fetchLinks = async () => {
            const [data, status] = await getRequest(null, "link/795");
            if (status == 200) {
                console.log(data.data)
                setLinks(data.data.sort((a, b) => a.sort - b.sort))
            }
            else {
                console.log(error)
            }
        };

        fetchLinks();
    }, []);
    return (
        <>
            {links.map((data, index) => {
                return <a className="dropdown-item" key={index} href={data.url}>{data.title}</a>
            })}

        </>
    );
}

export default EmergencyContact;