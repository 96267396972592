import React from "react";
// Css
import "../assets/styles/pages/style.css";
// React-Router
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

//useState
import { useState } from "react";

// Components:
import RelatedLink from "../components/RelatedLink";
import ContactInfo from "../components/ContactInfo";
import GrandFatherPlayingWithDaughter from "../assets/img/GrandFatherPlaywithKids.jpg";
import GirlOnABikeWithHerMom from "../assets/img/GirlRidingBikeLarge.jpg";
import Family from "../assets/img/FamilyWalkingInTheWoods.jpg";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";
import ScrollToTop from "../components/ScrollToTop";
import CarouselFade from "../components/CarouselFade";



const Home = () => {

  const [searchInput, setSearchInput] = useState("");
  const handleChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchForService();
    }
  }

  const searchForService = () => {

    window.open("//" + "www.google.com/search?q=ky_dbhdid_" + searchInput, '_blank');

  }

  return (
    <>
      <Navbar iD={"1"} />
 
      {/* Search Bar */}
      <article className="container-fluid central-nav-container wrapper slider">
        <CarouselFade />
      </article>
      <div className="outerContainer bodyRow">



        <h2 className="hero-header">Building a Stronger Community</h2>

        <div className="mainContentArea mainContent" id="bodyContent">
          <div>
            {/* <DepartmentHeader header="Department for Behavioral Health, Developmental and Intellectual Disabilities" /> */}
            <div className="container">
              <div className='bodyText'>


                <div className="subTitle ">
                  {/* <h1> Who We Are?</h1> */}
                  <div className="container overlap-box">

                    <div className="clearfix">
                      <img src={GrandFatherPlayingWithDaughter} className="container col-md-6 float-md-end mb-3 ms-md-3 Home-Image-Top-Box" alt="..." />

                      <p>
                      Thank you for visiting the Kentucky Department for Behavioral Health, Developmental, and Intellectual Disabilities home page. The Department is responsible for administering state and federally funded mental health, substance use disorder, developmental, and intellectual disability programs and services throughout the Commonwealth.
                      </p>

                      <p>
                        Promote health and well-being by facilitating recovery for people whose lives have been affected by mental illness and substance use, supporting people with intellectual or other developmental disabilities, and building resilience for all.
                      </p>

                      <p>
                        Our mission is to promote health, well-being, and resilience for all, facilitate recovery for people affected by mental illness and substance use, and support people with intellectual or other developmental disabilities.
                      </p>

                      <p>
                        Our vision is that all Kentuckians have access to quality services and supports to live full and healthy lives.
                      </p>

                      <p>
                        All Kentuckians have access to quality services and supports to live full and healthy lives.
                      </p>
                    </div>

                  </div>
                  <div className=" mb-4 value-card-home" >
                    <div className="row g-3">
                      <div className="col-md-4">
                        <img src={GirlOnABikeWithHerMom} className="img-fluid rounded-start second-box-image " alt="..." />
                        <img src={Family} className="img-fluid rounded-start second-box-image " alt="..." />


                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h4 class="card-title values-title">Values</h4>
                          <p class="card-text card-text-body">

                            <ul>

                              <li><b>C</b>ollaboration. We believe collaboration is essential to achieve our work.</li>

                              <li><b>C</b>hoice. We believe all people should have the fullest possible control over their own lives.</li>
                              <li><b>R</b>espect. We believe all people are valuable and have the right to lead meaningful, productive lives.</li>
                              <li><b>E</b>quity. We believe in equity and eradication of disparities to ensure all people have access to quality services.</li>
                              <li><b>A</b>dvocacy. We believe in supporting all people to advocate for themselves and others; diverse voices should be sought, heard, and considered in making decisions.</li>
                              <li><b>T</b>rauma-Informed & Resilience-Oriented Approaches. We believe in trauma-informed systems that promote individual, community and organizational resilience.</li>
                              <li><b>E</b>xcellence. We believe that service is collaborative, represents consumer needs, assures optimal use of public resources, and achieves the highest possible standard.</li>
                            </ul>
                            <div className="hero" id="mysearch">
                              {/* <h1 className="pageTitle sr-only">DBHDID Homepage</h1> */}
                              <div className="container">
                                <div className="wellness">
                                  <h2 className="search-text">How can we help?</h2>
                                  <div className="input-group home-search">
                                    <input className="form-control" onKeyDown={handleKeyDown} onChange={handleChange} value={searchInput} placeholder="Search for..." type="text" id="homeSearchInput" />
                                    <span className="input-group-btn">
                                      <button className="btn btn-primary" id="homeSearchBtn" onClick={searchForService} type="button">Search</button>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Quick Tabs */}
        <div id="toplinks" className="notices">
          <div className="container">
            <div className="homepage-quick">
              {/* <h2 className="helper-title">
                Need Help?
              </h2> */}
              <div className="row">
                {/* <div className="col-sm-6 col-md-3">
                  <div className="homepage-quick-cta">
                    <h2>Report Suspected Abuse, Neglect and Exploitation</h2>
                    <ul className="no-bullet">
                      
                      <li>
                        <a className="quick-links" href="https://chfs.ky.gov/agencies/dcbs/dpp/apb/Pages/default.aspx">Adult Abuse</a>
                      </li>
                      <li>
                        <a className="quick-links" href="https://chfs.ky.gov/agencies/dcbs/dpp/cpb/Pages/default.aspx">Child Abuse</a>
                      </li>
                      
                    </ul>
                  </div>
                </div> */}
                <div className="col-sm-6 col-md-3">
                  <div className="homepage-quick-cta">
                    <h2>Programs and Services</h2>
                    <ul className="no-bullet" >
                    <li>
                        <Link className="quick-links" to={"mh/adultmh"}>
                          <div>Adult Mental Health Treatment and Recovery</div>
                        </Link>
                      </li>
                      <li>
                        <Link className="quick-links" to={"mh/children"}>
                          <div>Children’s Behavioral Health and Recovery Services</div>
                        </Link>
                      </li>
                      <li>
                        <Link className="quick-links" to={"/ddid"}>
                          <div>Developmental and Intellectual Disabilities</div>
                        </Link>
                      </li>
                      <li>
                        <Link className="quick-links" to={"mh/ppp"}>
                          <div>Mental Health Promotion, Prevention and Preparedness</div>
                        </Link>
                      </li>
                      <li>
                        <Link className="quick-links" to={"/sud"}>
                          <div>Substance Use Disorder Treatment and Recovery</div>
                        </Link>
                      </li>


                      <li>
                        <Link className="quick-links" to={"/sud/bhpp"}>
                          <div>Substance Use Prevention</div>
                        </Link>
                      </li>
                     
                      
                      
                      
                      {/* <li>
                        <Link className="quick-links" to={"/cmhc"}>
                          <div>Community Mental Health Centers</div>
                        </Link>
                      </li> */}
                      {/* <li>
                        <Link className="quick-links" to={"/dpi"}>
                          <div>Program Integrity </div>
                        </Link>
                      </li> */}
                      {/* <li>
                        <Link className="quick-links" to={"/kbhdid/ccc.aspx"}>
                          <div>Commissions, Councils, and Committees </div>
                        </Link>
                      </li> */}
                      {/* <li>
                        <a className="quick-links" href="https://ccdd.ky.gov/Pages/index.aspx">
                          <div>Commonwealth Council on Developmental Disabilities</div>
                        </a>
                      </li> */}
                      {/* <li>
                        <Link className="quick-links" to={"/facilities"}>
                          <div>Facilites</div>
                        </Link>
                      </li> */}
                    </ul>

                  </div>
                </div>

                <div className="col-sm-6 col-md-3">
                  <div className="homepage-quick-cta">
                    <h2>Crisis Numbers and Hotlines</h2>
                    <ul className="no-bullet">
                      {/* <li >
                        <a className="quick-links" href="https://dbhdid.ky.gov/crisisnos.aspx">Crisis Lines by County</a>
                      </li> */}
                      {/* <li>
                        <Link className="quick-links" to={"/hotlines"}>National Hotlines and Other Contacts</Link>
                      </li> */}
                      <li>
                        <a className="quick-links" href="https://988lifeline.org/">Suicide Prevention Hotline</a>
                      </li>
                      <li>
                        <a className="quick-links" href="/crisisnos">County Contacts</a>
                      </li>
                      <li>
                        <a className="quick-links" href="https://988lifeline.org/">988 Suicide & Crisis</a>
                      </li>
                      <li>
                        <a className="quick-links" href="https://dbhdidtest.ky.gov/documents/ddid/HotlinesAndOtherContacts.pdf">Hotlines/ Other Contacts Lifeline</a>
                      </li>
                      <li>
                        <a className="quick-links" href="https://www.thehotline.org/">National Domestic Violence Hotline</a>
                      </li>
                      <li>
                        <a className="quick-links" href="https://hotline.rainn.org/online">National Sexual Assault Hotline</a>
                      </li>
                    </ul>
                  </div>
                </div>


                <div className=" col-sm-6 col-md-3">
                  <div className="homepage-quick-cta">
                    <h2>I am looking for...</h2>
                    <ul className="no-bullet">
                      {/* <li>
                      <Link className="quick-links" to={"/kdbhdid/case-management"} >Case Management</Link>
                    </li>
                    <li>
                      <Link className="quick-links" to={"/dbh/dhhs"}>Deaf and Hard of Hearing Services</Link>
                    </li>
                    <li>
                      <Link className="quick-links" to={"/afm"}>Division of Administration and Financial Management</Link>
                    </li>
                    <li>
                      <Link className="quick-links" to={"/dbh"}>Division of Behavioral Health</Link>
                    </li>
                    <li>
                      <Link className="quick-links" to={"/ddid"}>Division of Developmental and Intellectual Disabilities</Link>
                    </li> */}

                      <li>
                        <Link className="quick-links" to={"/crisis"}>Crisis Intervention Teams</Link>
                      </li>

                      <li>
                        <a className="quick-links" href="https://chfs.ky.gov/agencies/dail/Pages/hslp.aspx">Hart-Supported Living</a>
                      </li>
                      <li>
                        <Link className="quick-links" to={"/ProviderDirectory"}>Local Resources</Link>
                      </li>
                      <li>
                        <Link className="quick-links" to={"/kdbhdid/respite"}>Respite</Link>
                      </li>
                      <li>
                        <Link className="quick-links" to={"/mh/sp"}>Suicide Prevention</Link>
                      </li>
                      <li>
                        <Link className="quick-links" to={"/calendar"}>Training Information</Link>
                      </li>

                      <li><a className="quick-links" href="https://veterans.ky.gov/Pages/index.aspx?gad_source=1&gclid=Cj0KCQjwzZmwBhD8ARIsAH4v1gXDCNCLzk6IrBtm276qKdLGZv1SjncWn5M4__0oDkNlyqN2Dfov_T8aAnVrEALw_wcB">KDVA Homepage</a> </li>

                    </ul>
                  </div>
                </div>
                <div className="col-sm-6 col-md-3">
                  <div className="homepage-quick-cta">
                    <h2>I need to...</h2>
                    <ul className="no-bullet">
                      <li>
                        <Link className="quick-links" to={"/ddid"}>Find services for someone with an intellectual or developmental disability</Link>
                      </li>
                      <li>
                        <Link className="quick-links" to={"/sud"}>Find services for someone with a substance use disorder</Link>
                      </li>
                      <li>
                        <Link className="quick-links" to={"/mh"}>Find services for someone with mental health issues</Link>
                      </li>
                      <li>
                        <Link className="quick-links" to={"/ddid/scl-forms-general"}>Apply for the SCL or Michelle P Waiver</Link>
                      </li>
                      <li>
                        <Link className="quick-links" to={"/providerdirectory"}>Find a provider for substance use disorder treatment</Link>
                      </li>
                      <li>
                        <Link className="quick-links" to={"/providerdirectory"}>Find a provider for mental health services</Link>
                      </li>
                      {/* <li>
                      <Link className="quick-links" to={"/Contact"}>Report a complaint</Link>
                    </li> */}
                      <li>
                        <Link className="quick-links" to={"/crisisnos"}>Find help for my crisis</Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-sm-6 col-md-3">
                  <div className="panel panel-default">
                    <h2 className="panel-heading">How do I? </h2>
                    <div className="dfwp-column">
                      <div className="slm-layout-main groupmaker">
                        <ul className="no-bullet">
                          {/* 
                        <li><a className="quick-links" href="https://prd.webapps.chfs.ky.gov/kyfaces">Become a foster parent</a> </li> */}
                          {/* <li><a className="quick-links" href="https://chfs.ky.gov/agencies/dcbs/dcc/Pages/ccap.aspx">Apply for child care assistance</a> </li> */}


                          <li><HashLink className="quick-links" to="/dpi/cap#How to Become a Peer Support Specialist (PSS) in Kentucky" smooth>Become a Peer Support Specialist</HashLink> </li>
                          <li><HashLink className="quick-links" to="/dpi/cap#How to Become a Targeted Case Manager (TCM) in Kentucky" smooth>Become a Targeted Case Manager</HashLink> </li>
                          <li><HashLink className="quick-links" to="/dpi/cap#How to Become a Community Support Associate (CSA) in Kentucky" smooth>Become a Community Support Associate</HashLink> </li>
                          <li><Link className="quick-links" to="/mh/tcm">Become an Approved Training Provider for TCM</Link> </li>
                          <li><Link className="quick-links" to="/mh/csa">Become an Approved Training Provider for CSA</Link> </li>
                          <li><Link className="quick-links" to="/mh/pss">Become an Approved Training Provider for PSS</Link> </li>
                          <li><Link className="quick-links" to="/ddid/scl-newprovider">Become an SCL Provider</Link> </li>




                        </ul>

                      </div>
                    </div>

                  </div>


                </div>

                <div className="col-sm-6 col-md-3">
                  <div className="panel panel-default">
                    <h2 className="panel-heading">Connect with CHFS</h2>
                    <div className="list-group">
                      <div className="dfwp-column">
                        <div className="slm-layout-main groupmarker">
                          <ul className="no-bullet">
                            <li><a className="quick-links" href="https://chfs.ky.gov/Pages/index.aspx">CHFS HOME</a> </li>
                            <li><a className="quick-links" href="https://chfs.ky.gov/agencies/Pages/default.aspx">Department/Agencies</a> </li>
                            <li><a className="quick-links" href="https://chfs.ky.gov/Pages/sitemap.aspx">Site Map</a> </li>
                            <li><a className="quick-links" href="https://chfs.ky.gov/Pages/contact.aspx">Contact</a> </li>
                            <li><a className="quick-links" href="https://www.chfs.ky.gov/agencies/dbhdid/Pages/kore.aspx">KORE</a> </li>
                          </ul>

                        </div>
                      </div>

                    </div>
                  </div>

                </div>

                {/* <div className="col-sm-6 col-md-3">
                <div className="panel panel-default">
                  <h4 className="panel-heading">Kentucky Department of Veterans Affairs</h4>
                  <div className="list-group">
                    <div className="dfwp-column">
                      <div className="slm-layout-main groupmarker">
                        <ul className="no-bullet">
                          <li><a className="quick-links" href="https://chfs.ky.gov/Pages/index.aspx">KDVA Homepage</a> </li>
                          <li><a className="quick-links" href="https://veterans.ky.gov/Pages/contact.aspx">Contact</a> </li>
                          <li><a className="quick-links" href="https://veterans.ky.gov/Pages/Calendar.aspx">Calendar</a> </li>
                          <li><a className="quick-links" href="https://veterans.ky.gov/Benefits/Pages/default.aspx">Benefits</a> </li>


                        </ul>

                      </div>
                    </div>

                  </div>
                </div>

              </div> */}

                <div className="col-lg-6 col-md-3">
                  <RelatedLink pageID={"1"} />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <ScrollToTop />
      <Footer />
    </>







  )
}

export default Home;