import IsNullOrWhiteSpace from "./IsNullOrWhiteSpace";


function ContactInfoPerson({email, name, title, phone}){

    return(
        <li className="container-fluid">
            <i className="fa-li fa fa-user" aria-hidden="true"></i>
            <a className="person" href={"mailto:" + email}>
                <IsNullOrWhiteSpace arg={title} notNullComponent={<>{title}<br/></>}/>
                {name} <br />
            </a>
            <a className="phone" href={"tel:" + phone}>{phone}</a>
        </li>
    );
}

export default ContactInfoPerson;