import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import SanitizeHref from "../../../utils/SanitizeHref";
import DocumentsDropdown from "../../../components/DocumentsDropdown";

const dataReportsFacility_Content = [

    // {
    //     format: bodyHelperTypes.title,
    //     order: 10,
    //     content: "Facility Data Reports"
    // },
    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            <>Click on "Display by Facility" or "Display by Fiscal Year" below to choose a method for grouping the report's results, then select a report from one of the dropdown lists. The header above the list of reports indicates by which method the reports will be grouped.</>,
            <>
                <b>Note: </b>
                These reports open in a separate tab, so you may have to disable your popup blocker or allow popups from this site.
            </>,
            <>
                <a href={SanitizeHref("https://dbhdidtest.ky.gov/documents/isstatus.pdf?t=13254505222024")}>
                    View the Current Status of the Facility Information System Migration
                </a>
            </>,
            <>
                <DocumentsDropdown />
            </>
        ]
    },
];

export default dataReportsFacility_Content;