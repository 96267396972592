import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const cRD_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "CMHC Contract Reference Documents"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            <><Links linkTypeID="535" /></>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "Additional Contract Documents"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 40,
        content: [
            <><Links linkTypeID="538" /></>
        ]
    },
];

export default cRD_Content;