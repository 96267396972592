import React from "react";
import bodyHelperTypes from "../../utils/BodyHelperTypes";



const calendar_Content = [

    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            <><div data-tockify-component="calendar" data-tockify-calendar="dbhdid"></div></>     
        ]
    },
    
    


];

export default calendar_Content;