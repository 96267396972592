import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import DPRForms from "../../../components/DPRForms";

const dPR_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Department Periodic Reports Information - FY 2025"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            <><DPRForms /></>
        ]
    },
];

export default dPR_Content;