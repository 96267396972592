// Libs:
import React from 'react';
// css:
import "../../assets/styles/pages/style.css";
// Header
import DepartmentHeader from '../../components/DepartmentHeader';
// Layout
import LayoutOne from '../../layouts/bodyLayouts/LayoutOne';
import Navbar from '../../layouts/Navbar';
import Breadcrumbs from '../../components/buttons/Breadcrumbs';
import TableOfContent from '../../components/TableOfContent';


function Women() {
    return (
        <>
            <Navbar iD="38" />
            <Breadcrumbs />
            <div className="container outerContainer bodyRow">
                <div className="mainContentArea mainContent" id="bodyContent">
                    <div>
                        <div className='container body'>
                        <div className="row">
                                <div className=" col-9">
                            <DepartmentHeader header="Women's Substance Use Treatment" />
                            <div class="">
                                <div className="">

                                    <div className='bodyText'>
                                        <div className="subTitle">

                                            {/* <h4 id='Our Concerns'>Our Concerns</h4> */}

                                            <p>Pregnant individuals that are dependent on substances face tremendous stigma from their family, social networks, and society. According to the National Institute on Drug Abuse, more than 19.5 million women in the U.S. have used illicit substances in the last year and 8.4 million women have misused prescription substances during the past year.  </p>

                                            <p>
                                                Substance exposed infants and children have also been shown to have significantly higher rates of early mental health and behavioral problems, as well as higher rates of adverse birth outcomes, and require increased health care utilization after discharge.
                                            </p>

                                            <p>
                                                Opioid-dependent pregnant individuals often do not receive the treatment that they need and require, resulting in potential risks of harm to the baby. 
                                            </p>

                                            {/* <h4 id='Our Vision'>Our Vision</h4> */}

                                            <p>
                                                Across Kentucky, a variety of services exist and are provided to women, however they are  often not well coordinated, which may lead to ineffective care delivery. Our goal is to support implementation of coordinated services that can intervene at multiple stages: pre-pregnancy, prenatal, birth, neonatal, and  during childhood development.  
                                            </p>

                                            <h4 id='Service Initiatives'>Service Initiatives</h4>

                                            <p>As a collaborative effort, Kentucky is working across many agencies and departments to collect data annually with relation to people of child-bearing age, substance use during pregnancy, substance-effected births and substance-exposed births. </p>

                                            <p>
                                                The Division is working with the Department for Public Health (DPH) and Department for Community-Based Services (DCBS) to implement an initiative around <a href='https://dbhdidtest.ky.gov/dbh/document/siac/handouts/20160928.pdf'>plans of safe care</a> for babies identified as being substance-effected or potentially substance-exposed. A critical part of addressing concerns around substance-effected and/or substance-exposed infants assures that the child is able to live and thrive in a safe, stable, and nurturing environment during the early years of development and beyond.  This requires services and support in the community for the family, mother, infant and developing child.  
                                            </p>

                                            <p>

                                            In addition, the Kentucky Justice and Public Safety Cabinet, Office of Drug Control Policy (ODCP), in collaboration with the Department for Behavioral Health, Developmental and Intellectual Disabilities (DBHDID), supports annual competitive funding opportunities that aim to decrease incidence of Neonatal Abstinence Syndrome (NAS), advance the delivery of treatment and recovery supports for pregnant and parenting people with opioid use disorder (OUD) and expand support for children and families impacted by substance use disorder (SUD). 

                                            </p>

                                            <p>
                                                Through consistent early identification, appropriate referrals, and utilization of available services including case management, peer and family support services, therapeutic, medical, and other follow-up services, it is expected that both short and long term outcomes for families will improve.
                                            </p>

                                            {/* <p>
                                                The Division of Substance Use Disorder contracts with 14 regional Community Mental Health Centers (CMHCs) across
                                                Kentucky to provide substance treatment and recovery services. The substance Use Prevention Treatment and Recovery Block
                                                Grant funding requires that 20 percent of the funding be allocated specifically for women's substance use treatment and recovery services.
                                                In addition to the CMHCs, there are also several not-for-profit and private facilities and programs that offer a wide variety substance use treatment and recovery services that contribute to the vast availability of services for women in Kentucky.
                                            </p> */}

                                            <p>
                                                See related links below for additional resources.
                                            </p>



                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <LayoutOne pageID="38" />
                                </div>
                            </div>
                        </div>
                        <div className='col'>
                            <TableOfContent />
                        </div>

                    </div>
                </div>
            </div>
        </div >
            </div >
        </>
    );
};
export default Women;