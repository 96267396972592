import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import UnorderedList from "../../../layouts/bodyLayouts/helpers/UnorderedList";
import OrderedList from "../../../layouts/bodyLayouts/helpers/OrderedList";
import Links from "../../../components/Links";


const EvidenceBasedInitiativesContent = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Motivational Interviewing"

    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "Motivational Interviewing (MI) is a person-centered, goal-directed method for engagement and enhancing intrinsic motivation to change behavior by exploring and resolving ambivalence. Resolving ambivalence will increase an individual's engagement in treatment and enhance his or her ability to stay and complete treatment and to sustain the changes.",
            "Motivational Interviewing is not a technique or a set of techniques that is applied to people; rather, it is an interpersonal style, not at all restricted to formal counseling settings.",
            "The clinician practices motivational interviewing with five general principles in mind:",
            <UnorderedList items={[
                "Express empathy through reflective listening.",
                "Develop discrepancy between clients' goals or values and their current behavior.",
                "Avoid argument and direct confrontation.",
                "Adjust to client resistance rather than opposing it directly.",
                "Support self-efficacy and optimism."
            ]} />,
            "Motivational Interviewing is a complex, advanced skill that requires consistent personal monitoring to remain faithful to the model.",
            "Use the Contact Information to obtain further information."
        ]

    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "Recovery and Peer Support"

    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 40,
        content: [
            <>Recovery <br /><br />

                Recovery from mental illness is not only possible but expected. The President's New Freedom Commission Report (2003) envisioned "a future when everyone with a mental illness will recover, a future when mental illnesses can be prevented or cured, a future when mental illnesses are detected early, and a future when everyone with a mental illness at any stage of life has access to effective treatment and supports—essentials for living, working, learning, and participating fully in the community."



            </>,

            <><b>Defining "Recovery"</b><br /><br />
                In August 2010, a group of leaders in the behavioral health field, including people in recovery from mental illness and substance use disorders, met with the national agency called the Substance Abuse Mental Health Services Administration (SAMHSA). The team worked together to develop a working definition of recovery that would apply to both mental health and substance use populations. The team developed the following definition for recovery:
            </>,

            <>
                A process of change through which individuals improve their health and wellness, live a self-directed life, and
            </>,

            <>
                The team identified for dimensions that support recovery:
                <br />
                <br />

                <UnorderedList items={[
                    "Health",
                    "Home",
                    "Purpose",
                    "Community"
                ]} />
            </>,

            <>

                The team also identified ten Principles of Recovery:

                <br />
                <br />

                <ol>
                    <li>Recovery emerges from hope.</li>
                    <li>Recovery is person-driven.</li>
                    <li>Recovery occurs via many pathways.</li>
                    <li>Recovery is holistic.</li>
                    <li>Recovery is supported by peers and allies.</li>
                    <li>Recovery is supported through relationship and social networks.</li>
                    <li>Recovery is culturally-based and influenced.</li>
                    <li>Recovery is supported by addressing trauma.</li>
                    <li>Recovery involves individual, famiy, and community strengths, and responsibility.</li>
                    <li>Recovery is based on respect.</li>
                </ol>


            </>,

            <>Click "SAMHSA Recovery" in the Related Links box to find more about the four dimensions and the ten Principles of Recovery.</>




        ]

    },
    {
        format: bodyHelperTypes.title,
        order: 50,
        content: "Peer Support"

    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 60,
        content: [
            "The President's New Freedom Commission Report (2003) also outlines a plan to transform mental health care in America to promote a recovery-oriented service system. Peer support is an important element in this transformation process. The Peer Specialist Service is a structured and scheduled therapeutic activity with an individual client or group, provided by a trained, self-identified consumer of mental health services. A Peer Specialist guides clients toward the identification and achievement of specific goals defined by the client and specified in the Treatment Plan.",
            <><b>How to Become a Peer Support Specialist</b> <br /><br />
                To become qualified, a Kentucky Peer Specialist (KPS) completes 30 hours of training and passes both a written and oral test. The job of a KPS is not to replace current clinical mental health staff but to offer additional and/or alternative options to help people in their efforts to recover.
            </>,
            "Regular supervision is required and each qualified Kentucky Peer Specialist must earn at least 6 hours of continuing education each year. To locate a training event in Kentucky, access the following links by the type of Peer Specialist.",

            <Links linkTypeID="786" />,

            <>
                <b>How to Become a Trainer for Peer Support Curricula</b><br /><br />

                The Division of Mental Health (DMH) supports consumer-driven recovery-oriented services. DMH approves training curricula for providers that request to become trainers for the Peer Specialist types listed above. For more information on how to become a trainer, access the "Curriculum Approval Process for PSS" link in Related Links.
            </>,
            <>
                <b>For More Information</b><br/><br/>
                The Kentucky regulation for adult peer support is 908 KAR 2:220 (see Related Links). For additional information about this service and the required training and supervision requirements, access "Curriculum Approval Process for PSS" in Related Links.
            </>
        ]

    },
    {
        format: bodyHelperTypes.title,
        order: 70,
        content: "NIATx"

    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 80,
        content: [
            "NIATx, formerly The Network for the Improvement of Addiction Treatment, is a partnership between the Robert Wood Johnson Foundation's Paths to Recovery program, the Center for Substance Abuse Treatment's Strengthening Treatment Access and Retention (STAR) program, the National Institute on Drug Abuse, and a number of independent addiction treatment organizations.",
            "NIATx works with addiction treatment providers to make more efficient use of their capacity and shares strategies for improving treatment access and retention.",
            "The Division of Behavioral Health (DBH) is working collaboratively with the University of Wisconsin–Madison's Center for Health Enhancement Systems Studies, where the national NIATx technical assistance center is located, to promote the use of the NIATx model as a performance improvement strategy within Kentucky's public substance abuse and mental health service system.",
            "The model is based on evidence-based practices in process improvement gleaned from a variety of fields, including engineering, business and social services. It is understood that improving access to and retention in treatment can lead to better client outcomes.",
            "DBH is promoting the use of NIATx as a practice improvement tool because it has been shown to lead to increased client satisfaction and substantial change. The four aims of NIATx are to:",

            <><UnorderedList items={[
                "Reduce waiting time.",
                "Reduce no-shows",
                "Increase admissions.",
                "Increase continuation."
            ]} /></>, 
            "The NIATx model is based on five key principles:",

            <><OrderedList items={[
                "Understand and involve the customer.",
                "Fix key problems that keep the CEO awake at night.",
                "Pick a powerful change leader.",
                "Get ideas from outside the organization or field.",
                "Use rapid-cycle testing to establish effective changes."
            ]} /></>,

            "Use the Contact Information to obtain materials and further information.",

            <Links linkTypeID="787" />
        ]

    },


];

export default EvidenceBasedInitiativesContent;