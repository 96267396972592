import React from "react";
import bodyHelperTypes from "../utils/BodyHelperTypes";



const olmstead_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Olmstead Planning"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "Olmstead planning activities in Kentucky began with grant funding from the Robert Wood Johnson Foundation and the Center for Health Care Strategies that convened a broad stakeholder group in 2000. The Olmstead Planning Committee presented a final report to the Cabinet for Health and Family Services in October 2001. In response, the Cabinet issued an Olmstead Compliance Plan in 2002.",
            "Kentucky's Legislature has provided $800,000 in Olmstead 'Wraparound' funding to assist in discharge planning efforts for individuals with complex service needs who are long-term inpatients in state psychiatric facilities. The Department has established an Olmstead Transition Committee at each of the four state hospitals that meet and plan for community placement.",
            "These Transition Committees comprise hospital discharge staff, community service providers, state staff and family and consumer organization members. They have successfully transitioned many individuals into the community who would not otherwise have had the opportunity to leave the hospital setting.",
            "In response to the Olmstead decision, the Department continues to explore new initiatives to reduce institutional care levels, expand access to community-based services, and help people return to their home communities.",
            "In 2009, the Department, in collaboration with Kentucky Housing Corporation, created the Olmstead Housing Initiative, which provides rental assistance and funds for deposits, furniture and moving expenses for individuals with serious mental illness who meet Olmstead criteria.",
            "The Department has also produced three DVDs covering Psychiatric Advanced Directives, Supportive Housing, and The Reality of Recovery that are available for use in educating consumers, providers, family members,  and others. In the area of children's mental health, a series of training and technical assistance initiatives is currently being conducted to further educate parents and providers regarding the Individuals with Disabilities Education Act, the Americans with Disabilities Act, and Kentucky's newly revised laws.",
            "Training sessions are also available to providers and parents about working with adolescents, and plans exist to offer Trauma Informed Care training statewide. A work group has been established to further address the need for additional services and supports for transition-age youth with mental health and substance abuse disorders, including those who are aging out of the state's foster care system."

        ]
    }

];

export default olmstead_Content;