//Using the react library
import React from "react";
//Using the react library:
import { useEffect, useState } from "react";
//API Middle man
import useApi from "../utils/UseAPI";
// React loading spinner
import Spinner from 'react-bootstrap/Spinner';
// Check if Contact info is null
import { isNullOrWhiteSpace } from "../utils/StringUtils";
import SanitizeHref from "../utils/SanitizeHref";
import { each } from "jquery";
import service from '../data/CSBData.json'



function BHPACDocuments(props) {

    const { error, processing, getRequest } = useApi();
    const [documents, setDocuments] = useState([]);

    useEffect(() => {
        const fetchLinks = async () => {
            const [data, status] = await getRequest(null, "/document");
            if (status == 200) {
                FormDocuments(data.data);
            }
            else {
                console.log(error)
            }
        };
        fetchLinks();
    }, []);
    function FormDocuments(documentData) {
        const item = [];
        documentData.forEach((doc) => {
            if(doc.typeId == props.iD && doc.url.includes("/kbhpac/") ){
                item.push(doc);
                // console.log(item)
            }      
        })
        setDocuments(item);
    }
    return (
        <>    
            <div className="col-md-4 col-md-3">
                {/* Return List */}
                <select className="form-select" >
                    <option>Select a meeting date</option>

                    {
                        documents.map((data, index) => {
                            console.log(data)
                            return (
                                
                                <option key={index} value={data.typeId} onClick={""} className="form-select"
                                ><a href={data.url}>{data.name}</a></option>
                            )
                        })
                    }
                </select>
            </div>
        </>

    );

}

export default BHPACDocuments;


