import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import UnorderedList from "../../../layouts/bodyLayouts/helpers/UnorderedList";

const SAKPNContent = [
    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
                <>
                The <a href="https://sites.google.com/view/kypreventionnetwork/home">Kentucky Prevention Network (KPN) </a>promotes evidence-based substance use/misuse prevention efforts, through training, education, and networking. KPN strives to increase the understanding of prevention strategies to reduce substance use/misuse and as a key component of public health. KPN encourages collaboration among prevention professionals, volunteers, community members, and other stakeholders in the interest of a healthy Commonwealth.
                </>
                ,

                "KPN supports and advocates for data-driven decisions and evidence-based practices for effective alcohol, tobacco, and other substance use/misuse prevention strategies, resources, and programs.  KPN recognizes that there is a relationship between mental health wellness, suicide prevention, and substance use and promotes the use of the Strategic Prevention Framework and upstream prevention strategies to address those in the community.  "
                ]

        

    },
                ];

                export default SAKPNContent;