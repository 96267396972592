//===================== Libraries ================================
//Using the react library
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

// ===================== Components ==============================
//import Department Button
import Department from "../components/buttons/Department";
// ===================== Assets =================================
//css
import "../assets/styles/layout/navbar.css";
//import assets: Grabbing the the CHFS Logo
import logo from "../assets/img/logos/kentucky-icons/Team-Kentucky_CHFS-Branding_blue_50.png";
import crisisNumber from "../assets/img/logos/988/988-horizontal-white-black.jpg"
import arrows from "../assets/img/logos/988/angles-right-solid.svg"
//import data (json)
import data from "../data/data.json";
import GoogleTranslateBar from "../components/GoogleTranslateBar";
import Breadcrumbs from "../components/buttons/Breadcrumbs";
import ContactInfo from "../components/ContactInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useApi from "../utils/UseAPI";
// React loading spinner
import Spinner from 'react-bootstrap/Spinner';
// Check if Contact info is null

import IsNullOrWhiteSpace from "../components/IsNullOrWhiteSpace";
import { render } from "react-dom";
import EmergencyContact from "../components/EmergencyContact";


 function Navbar(props) {

    const [searchInput, setSearchInput] = useState("");
    


    const handleChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);


    };


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchForService();
        }
    }

    const searchForService = () => {

        window.open("//" + "www.google.com/search?q=ky_dbhdid_" + searchInput, '_blank');

    } 


    // const currentPageID = currentPageStore((state) => state.currentPageID);


    // // Departments
    return (

        <>
            <nav className=' bottom-nav'>
                <nav className=" navbar-text-info">
                    <div className="container-fluid">
                        <div className="top-page-nav-text">
                        {/* <i className='fa-solid fa-exclamation' style={{'font-size':'36px'}}/>  */}
                        <a href="https://www.samhsa.gov/sites/default/files/988-around-the-us.mp4">Learn more about 988 and the resources and services offered.</a>
                        <br />            
                        <a href="https://www.samhsa.gov/find-support/in-crisis"><img alt="In Crisis?" style={{ maxWidth: "10.3%", }} className='' src={crisisNumber} /> </a><b>In Crisis? <a className="" href="tel: 988">Call</a> or <a href="sms:988">Text</a> 988 </b> <a href="https://www.samhsa.gov/find-support/in-crisis"><img style={{ maxWidth: "1.1%", marginBottom:"4px" }} src={arrows} /></a>
                        
                        

                         
                            
                         {/* class="svg-inline--fa fa-message-exclamation" */}
                        </div>
                    </div>
                </nav>
                {/* The start of the nav content */}
                <nav className="official-nav-logo-text-bar">
                    <div className="container-fluid">
                        <div className='navbar-header'>

                            <span className="official-ky-text">Ky.<small>gov </small><div className='slogan-hide'>An Official Website of the Commonwealth of Kentucky</div> </span>


                        </div>
                    </div>
                </nav>





                <div class=" central-nav-container button-nav middle-nav-formation justify-content-center">

                    <div className='lower-navs-layout flex-vcenter'>
                        <nav className="navbar ">




                            {/* <a href="https://dbhdid.ky.gov/" className="navbar-brand department-text-logo" alt="DBHDID Home Page">&nbsp;&nbsp;&nbsp;&nbsp;<b>DBHDID Home Page</b></a> */}
                            <div className="navbar-brand department-text-logo">Department for Behavioral Health, Developmental and Intellectual Disabilities</div>




                            <div className="google-widget-translate" role="language">


                            <GoogleTranslateBar />



                            </div>


                        </nav>
                    </div>
                </div>


                <div className='line-separation'></div>





                <div className="bg-white navbar-expand-lg navbar-light bg-light">


                    <div className="container button-nav">

                        <div className="central-nav-container">

                            <a className="container navbar-brand" href="https://dbhdid.ky.gov/"><img alt="Team Kentucky Cabinet For Health and Family Services" className='container-fluid dbhdid-logo' src={logo} /></a>




                            
                            <button className="navbar-toggler btn-btn-primary nav-button-design" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span> 
                                <div className="move-menu-word"> Menu</div>
                               
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className=" navbar-nav nav-layout  department-nav">
                                    <li className=" nav-item  dropdown">
                                        <a className="  nav-link dropdown-toggle font-custom " href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Divisions
                                        </a>
                                        <ul className="dropdown-menu  " aria-labelledby="navbarDropdown">
                                            {data.departmentNames.map((data, index) => {
                                                return <Department key={index} path={data.path} department={data.title} />
                                            })}
                                        </ul>
                                    </li>
                                    <li className=" nav-item  dropdown">
                                        <a className="nav-link center-nav-tabs dropdown-toggle font-custom " href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Reports
                                        </a>
                                        <ul className="dropdown-menu " aria-labelledby="navbarDropdown">

                                            {data.cmhc.map((data, index) => {
                                                return <Department key={index} path={data.path} department={data.title} />
                                            })}



                                        </ul>
                                    </li>

                                    <li className="nav-item center-nav-tabs">
                                        <a className="nav-link  font-custom" aria-current="page" href="/facilities">Facilities</a>
                                    </li>
                                    <li className=" nav-item  dropdown">
                                        <a className="nav-link center-nav-tabs dropdown-toggle font-custom " href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            CMHC
                                        </a>
                                        <ul className="dropdown-menu " aria-labelledby="navbarDropdown">

                                            {data.reports.map((data, index) => {
                                                return <Department key={index} path={data.path} department={data.title} />
                                            })}



                                        </ul>
                                    </li>
                                    <li className=" nav-item center-nav-tabs">
                                        <a className="nav-link  font-custom" aria-current="page" href="/calendar">Calendar</a>
                                    </li>
                                    <li className=" nav-item center-nav-tabs">
                                        <a className="nav-link  font-custom" aria-current="page" href="/providerdirectory">Provider Directory</a>
                                    </li>
                                    <li  className="  nav-item  dropdown dropdown-width">
                                        <a  className=" nav-link center-nav-tabs dropdown-toggle font-custom " href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Contact Us
                                        </a>
                                        <ul className=" container dropdown-menu dropdown-width " aria-labelledby="navbarDropdown ">
                                        <ContactInfo pageID={props.iD}/>
                                            <EmergencyContact />
                                        </ul>
                                    </li>


                                </ul>

                                <div className=" ">
                                    <form className="container d-flex searchbar">
                                        <input className=" form-control me-2" onKeyDown={handleKeyDown} onChange={handleChange} value={searchInput} type="search" placeholder="Search for Services... " size="" aria-label="Search" />
                                        <button className="btn btn-primary btn-size" onClick={searchForService} type="submit">Search</button>
                                    </form>
                                </div>


                            </div> 
                        </div>


                    </div>


                </div>






            </nav>
                    {/* <Breadcrumbs /> */}
            <nav className=" ">

                {/* <Breadcrumbs /> */}

            </nav>


        </>

    );
}

export default Navbar;
