//Using the react library
import React from "react";
//Using the react library:
import { useEffect, useState } from "react";
//API Middle man
import useApi from "../utils/UseAPI";
// React loading spinner
import Spinner from 'react-bootstrap/Spinner';
// Check if Contact info is null
import { isNullOrWhiteSpace } from "../utils/StringUtils";
import SanitizeHref from "../utils/SanitizeHref";
import { Link } from "react-router-dom";



function DataGuideList() {

    const { error, processing, getRequest } = useApi();
    const [content, setContent] = useState([]);
    
    
    useEffect(() => {

        const fetchLinks = async () => {
            const [data, status] = await getRequest(null, "cmhc/data-guide");
            if (status == 200) {
                setContent(data.data)
            }
            else {
                console.log(error)
            }
        };
        fetchLinks();
    }, []);

    return (

        <>
            <div className="col-md-4 col-md-3">          
                    {/* Return List */}
                    <select className="form-select" onChange={(e) =>{
                        window.location = e.target.value;
                    }}>
                    <option>Select a Report</option>
                        {
                            processing ? <Spinner animation="border" variant="success" /> : content.map((data, index) => {
                                return (
                                    <option value={"https://dbhdid.ky.gov/DataReports/Reports.aspx?r="+data.reportName+"&x=rpt"} key={index} className="form-select">{data.reportDesc}</option>
                                )
                            })
                        }
                    </select>
              
            </div>
        </>

    );
}

export default DataGuideList;