import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";
import DataGuideList from "../../../components/DataGuideList";


const dataCommitteeContent = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Quality Management & Outcomes Team (QMOT)"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 20,
        content: "QMOT makes recommendations to provide direction for the collection of information relevant to desired outcomes, methods of measurement, and design of processes for continuous outcomes management across the Department. The team evaluates outcomes that support the provision of quality services and explores areas for improvement."
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "Joint Committee on Information Continuity (JCIC)"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 40,
        content: [
            "JCIC makes recommendations concerning information management to the Department. The committee facilitates the development of data-related contract items between the Department and Certified Mental Health Centers (CMHCs). As a central function, the committee provides direction and assistance in the continued development an information system to manage a public behavioral health system.",
            <>These data committees form part of the department's committee structure to coordinate data use, data collection, and data quality improvement. These committees are supported by DBHDID Commissioner Office leadership, Division Directors, and Branch Staff representation.
            
            <br/>
            <br/>
            </>
    ]
    }




];

export default dataCommitteeContent;