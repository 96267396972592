import React, { useState, useEffect } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import axios from 'axios';

function ProviderOptions({ formData, setFormData }) {
    const [healthPlans, setHealthPlans] = useState([]);
    const [otherProviders, setOtherProviders] = useState([]);
    const [ipServices, setIpServices] = useState([]);
    const [currentSubOptions, setCurrentSubOptions] = useState([]);

    useEffect(() => {
        const fetchHealthPlans = async () => {
            try {
                //const response = await axios.get('https://localhost:7245/providers/health-plans');
                const response = await axios.get('https://providerdirectory.dbhdidtest.ky.gov/api/providers/health-plans');
                //const response = await axios.get('https://dbhdidtest.ky.gov/provdirapi/providers/health-plans');
                //const response = await axios.get('https://dbhdid.ky.gov/provdirapi/providers/health-plans');
                //console.log('Health Plans:', response.data); // Debug log
                setHealthPlans(Array.isArray(response.data) ? response.data : []);
            } catch (error) {
                console.error('Error fetching health plans:', error);
            }
        };
        fetchHealthPlans();
    }, []);

    useEffect(() => {
        const fetchServices = async () => {
            try {
                //const response = await axios.get('https://localhost:7245/providers/services');
                const response = await axios.get('https://providerdirectory.dbhdidtest.ky.gov/api/providers/services');
                //const response = await axios.get('https://dbhdidtest.ky.gov/provdirapi/providers/services');
                //const response = await axios.get('https://dbhdid.ky.gov/provdirapi/providers/services');
                //console.log('Services:', response.data); // Debug log
                setIpServices(Array.isArray(response.data) ? response.data : []);
            } catch (error) {
                console.error('Error fetching services:', error);
            }
        };
        fetchServices();
    }, []);

    useEffect(() => {
        const fetchOtherProviders = async () => {
            try {
                //const response = await axios.get('https://localhost:7245/providers/other-providers');
                const response = await axios.get('https://providerdirectory.dbhdidtest.ky.gov/api/providers/other-providers');
                //const response = await axios.get('https://dbhdidtest.ky.gov/provdirapi/providers/other-providers');
                //const response = await axios.get('https://dbhdid.ky.gov/provdirapi/providers/other-providers');
                //console.log('Other Providers:', response.data); // Debug log
                setOtherProviders(Array.isArray(response.data) ? response.data : []);
            } catch (error) {
                console.error('Error fetching other providers:', error);
            }
        };
        fetchOtherProviders();
    }, []);
    
    useEffect(() => {
        const fetchSubOptions = async () => {
            if (formData.otherProviders && formData.otherProviders.length > 0) {
                const selectedOtherProvider = formData.otherProviders[0];
                if (selectedOtherProvider) {
                    try {
                        //console.log(`Fetching sub options for: ${selectedOtherProvider}`);
                        //const response = await axios.get(`https://localhost:7245/providers/services-by-provider-type/${selectedOtherProvider}`);
                        const response = await axios.get(`https://providerdirectory.dbhdidtest.ky.gov/api/providers/services-by-provider-type/${selectedOtherProvider}`);
                        //const response = await axios.get(`https://dbhdidtest.ky.gov/provdirapi/providers/services-by-provider-type/${selectedOtherProvider}`);
                        //const response = await axios.get(`https://dbhdid.ky.gov/provdirapi/providers/services-by-provider-type/${selectedOtherProvider}`);
                        //console.log('Sub options fetched:', response.data); // Debug log
                        setCurrentSubOptions(Array.isArray(response.data) ? response.data : []);
                    } catch (error) {
                        console.error('Error fetching sub options:', error);
                    }
                }
            } else {
                setCurrentSubOptions([]);
            }
        };
        fetchSubOptions();
    }, [formData.otherProviders]);

    const handleHealthPlanChange = (e) => {
        setFormData(prev => ({ ...prev, groups: [e.target.value] }));
    };

    const handleOtherProviderChange = (e) => {
        const value = e.target.value;
        console.log('Other provider selected:', value);
        setFormData(prev => ({ ...prev, otherProviders: [value] }));        
    };

    const handleIpServiceChange = (e) => {
        const value = Array.from(e.target.selectedOptions, option => option.value);
        setFormData(prev => ({ ...prev, services: value }));        
    };

    const handleSubOptionChange = (e) => {
        const value = Array.from(e.target.selectedOptions, option => option.value);
        setFormData(prev => ({ ...prev, selectServices: value }));
    };

    const renderTooltip1 = (props) => (
        <Tooltip id="button-tooltip" {...props} className="tooltip-custom">
            Select your desired health plan.
        </Tooltip>
    );

    const renderTooltip2 = (props) => (
        <Tooltip id="button-tooltip" {...props} className="tooltip-custom">
            Choose one or more Service.
        </Tooltip>
    );

    const renderTooltip3 = (props) => (
        <Tooltip id="button-tooltip" {...props} className="tooltip-custom">
            The 'Select Services (optional)' sub-menu will not appear on some 'Other Providers' selections.
        </Tooltip>
    );

    return (
        <div className="provider-options-container">
            <div className="provider-options-section">
                <OverlayTrigger
                    placement="auto"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip1}
                >
                    <strong>Health Plan<span className="info-icon">i</span><br /></strong>
                </OverlayTrigger>
                <select value={formData.groups[0] || ''} onChange={handleHealthPlanChange} className="provider-options-select">
                    <option value="">Select a Health Plan</option>
                    {Array.isArray(healthPlans) && healthPlans.map(plan => (
                        <option key={plan.groupId} value={plan.groupName}>{plan.groupName}</option>
                    ))}
                </select>
            </div>

            <div className="provider-options-section">
                <OverlayTrigger
                    placement="auto"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip2}
                >
                    <strong>Services<span className="info-icon">i</span><br /></strong>
                </OverlayTrigger>
                <select multiple={true} value={formData.services || []} onChange={handleIpServiceChange} className="provider-options-select">
                    {Array.isArray(ipServices) && ipServices.map(service => (
                        <option key={service.serviceUid} value={service.serviceUid}>{service.serviceDesc}</option>
                    ))}
                </select>
            </div>

            <div className="provider-options-section">
                <OverlayTrigger
                    placement="auto"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip3}
                >
                    <strong>Other Providers<span className="info-icon">i</span><br /></strong>
                </OverlayTrigger>
                <select value={formData.otherProviders[0] || ''} onChange={handleOtherProviderChange} className="provider-options-select">
                    <option value="">Select a Provider</option>
                    {Array.isArray(otherProviders) && otherProviders.map(provider => (
                        <option key={provider.providerTypeId} value={provider.providerTypeId}>{provider.providerTypeDesc}</option>
                    ))}
                </select>
            </div>

            {currentSubOptions.length > 0 && (
                <div className="provider-options-section provider-options-sub-section">
                    <label><strong>Select Services (optional)</strong></label>
                    <br />
                    <select multiple={true} value={formData.selectServices || []} onChange={handleSubOptionChange} className="provider-options-select">
                        {Array.isArray(currentSubOptions) && currentSubOptions.map((subOption) => (
                            <option key={subOption.serviceUid} value={subOption.serviceUid}>{subOption.serviceDesc}</option>
                        ))}
                    </select>
                </div>
            )}
        </div>
    );
}

export default ProviderOptions;
