import { useHeadsObserver } from '../utils/useObserver'
import { useEffect, useState } from 'react'
import { HashLink } from 'react-router-hash-link';
import RelatedLink from './RelatedLink';




const getClassName = (level) => {
  switch (level) {
    case 1:
      return 'head1'
    case 2:
      return 'head2'
    case 3:
      return 'head3'
    case 4:
      return 'head4'
    case 5:
      return 'head5'
    default:
      return null
  }
}



function TableOfContent({pageID}) {
  const [headings, setHeadings] = useState([])
  const { activeId } = useHeadsObserver()

  useEffect(() => {
    const elements = Array.from(document.querySelectorAll("h1, h2, h3, h4, h5"))
      .map((elem) => ({
        id: elem.innerText,
        text: elem.innerText,
        level: Number(elem.nodeName.charAt(1))
      }))
    setHeadings(elements);
  }, [])


  return (
    <>
    <nav className=' navbar-expand-lg  table-of-content bg-white-body-tertiary'>
      <button className="container tof-design navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">

      
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#ffffff" d="M40 48C26.7 48 16 58.7 16 72v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V72c0-13.3-10.7-24-24-24H40zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM16 232v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V232c0-13.3-10.7-24-24-24H40c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V392c0-13.3-10.7-24-24-24H40z"/></svg><span style={{display:"hidden !important"}}></span> 
       
      </button>
      <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
        <ul className='container tof-card'>
        <b style={{fontSize: "22px"}}>On this Page</b><br/><br/>
          {headings.map(heading => (
            <li
              key={heading.id}
              className={getClassName(heading.level)}
              style={{borderBottom:" 1px dotted #0065b8", }}
            >
              <HashLink
                to={`#${heading.id}`}
                style={{
                  color: activeId === heading.id ? "#08774b" : "#0065b8", 
                  // borderBottom:" 1px dotted #0065b8",
                  fontWeight: "bold",
                  width: "200px"
                }}
                scroll={(e) => e.scrollIntoView({ behavior: 'smooth' })}
              >
                {heading.text}
              </HashLink>
            </li>
          ))}
    <br/>
    <br/>
    <RelatedLink pageID={pageID} />   
        </ul>
      </div>
    </nav>

  
    </>
  )

}
export default TableOfContent