import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import UnorderedList from "../../../layouts/bodyLayouts/helpers/UnorderedList";


const BHPACContent = [
    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            "The Kentucky Behavioral Health Planning and Advisory Council is seeking membership applications from individuals to represent:",
            <>
                <UnorderedList items={[
                    "Adults with Lived Experience of a mental health disorder, a substance use disorder, or a co-occurring mental health/substance use disorder",
                    "Young Adults with Lived Experience (ages 18 – 25) of a mental health disorder, a substance use disorder, or a co-occurring mental health/substance use disorder",
                    "Family members of an adult with lived experience of a mental health disorder, a substance use disorder, or a co-occurring mental health/substance use disorder"
                ]} />
            </>,
            "Membership eligibility is described on Page 1 of the Membership Application (see Related Links)."
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 20,
        content: "Council Description"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 30,
        content: [
            "Established by administrative order in 1989, the Kentucky Behavioral Health Planning and Advisory Council conforms to Public Law 102-321, which requires formulation of such a council and specifies its composition of 51 percent consumers and family members of individuals with mental health and substance use disorders",
            "The council is charged with the following duties:"
        ]

    },
    {
        format: bodyHelperTypes.title,
        order: 40,
        content: "Council Duties"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 50,
        content: [ <><UnorderedList items={[
            "Report directly to the Commissioner of DBHDID.",
            "Assist DBHDID in designing a comprehensive, recovery-oriented system of care.",
            "Advise DBHDID on the use of the Substance Use Prevention, Treatment and Recovery Services Block Grant (SUPTRS-BG) funds and the Mental Health Block Grant (MHBG) funds, and on the quality of statewide, recovery-oriented behavioral health services.",
            "Review the biennial combined SUPTRS-BG and MHBG Application and annual SUPTRS – BG and MHBG Reports pursuant to Public Law 102-321, Section 1915(a), and submit recommendations to DBHDID prior to the September 1 and December 1 due dates, respectively.",
            "Advocate for individuals in recovery, children and youth with behavioral health challenges, and family members.",
            "Monitor, review, and evaluate, no less than once a year, the allocation and quality of statewide, recovery-oriented behavioral health services"
        ]} /></>
            
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 60,
        content: "Council Meetings and Committees"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 70,
        content: "The full council meets quarterly and has several established committees, including Executive, Bylaws, Membership, and Finance and Data, as well as ad hoc committees deemed appropriate by the council membership."
    },
    {
        format: bodyHelperTypes.title,
        order: 80,
        content: "Council Mission and Vision"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 90,
        content: [
            "The council’s mission and vision are as follows:  ",
            <><b>Mission: </b>The council is the active voice promoting awareness of and access to effective, affordable, recovery-oriented and resiliency-based services in all communities</>,
            <><b>Vision: </b>We believe that all children, adolescents, and adults in the Commonwealth have the right to excellent, recovery-oriented, resilience-based behavioral health services that are affordable, equitable, client-driven, and assists community members to achieve their full potential, and live and thrive in their community.</>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 100,
        content: "Council Membership"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 110,
        content: [
            'The council is composed of representatives from state agencies, providers, individuals with lived experience of mental health and/or substance use disorders, family members of individuals with lived experience of mental health and/or substance use disorders, parents/guardians of a child with behavioral health challenges, and two young adults with lived experience of mental health and/or substance use disorders.  Access the "Membership List" link in the Related Links box for a list of members.',
            "The council currently has 37 members:",
            <><UnorderedList items={[
                "Six individuals with lived experience of mental health and/or substance use disorders.",
                "Six parents/grandparents/guardians/foster parents who are caring for a child (birth through age 20) with behavioral health challenges",
                "Six family members of an individual with lived experience of mental health and/or substance use disorders.",
                "Two young adults (age 18-25) with lived experience of mental health and/or substance use disorders.",
                "Four representatives of advocacy organizations that DBHDID contracts with for services.",
                "Two provider organization representatives.",
                "Eleven state agency representatives."
            ]} /></>,
            "The ratio of parents of children with behavioral health challenges to other members of the council must be sufficient to provide adequate representation of these children.",
            <>Most importantly, the federal law that governs the council (Public Law 102-321) states that <b>not </b>less than 50 percent of the members of the council are individuals who are not state employees or providers of mental health services.</>
            
        ]
    },

];

export default BHPACContent;