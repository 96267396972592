import React from 'react';

import "../../assets/styles/pages/style.css";

import DepartmentHeader from '../../components/DepartmentHeader';

import LayoutOne from '../../layouts/bodyLayouts/LayoutOne';
import Navbar from '../../layouts/Navbar';
import ScrollToTop from '../../components/ScrollToTop';
import Footer from '../../layouts/Footer';
import Breadcrumbs from '../../components/buttons/Breadcrumbs';
import TableOfContent from '../../components/TableOfContent';
import Links from '../../components/Links';


function DDIDHome() {
    return (

        <>
            <Navbar iD="267" />
            <Breadcrumbs />
            <div className="body outerContainer bodyRow main-body">
                <div className="mainContentArea mainContent" id="bodyContent">
                    <div>
                        <div className='container body'>
                            <div className="row">
                                <div className=" col-9">
                                    <DepartmentHeader header="Developmental and Intellectual Disabilities" />
                                    <div className='bodyText'>
                                        <div className="">

                                            <h1 id='Mission Statement'>Mission Statement</h1>
                                            <p>
                                                It is the mission of the Division of Developmental and Intellectual Disabilities (DDID) to empower each person to realize his or her place in the community as a citizen of the Commonwealth of Kentucky.
                                                To accomplish this mission, DDID will partner with and support persons with intellectual or developmental disabilities, families, advocates, stakeholders and government agencies.
                                            </p>


                                            <h1 id='Vision'>Vision</h1>

                                            <p>
                                                People, throughout their lifespan, are active, contributing and respected members of their community.
                                                People have the ability to choose from a full array of effective and meaningful natural and paid supports to access what is important for them and to access and maintain what is important to them.
                                            </p>

                                            <h1 id='Values'>Values</h1>

                                            <p>
                                                The following principles guide the work of DDID staff as we strive to make a positive difference in the lives of all individuals with whom we come in contact.

                                            </p>


                                            <h4 id='Person-Centered Thinking and Communities'>Person-Centered Thinking and Communities</h4>

                                            <p>
                                                Person-centered thinking drives our actions by emphasizing the importance of each person. Communities are the first places to seek, identify and nurture supports and opportunities.
                                            </p>

                                            <h4 id='Participation in the Community'>Participation in the Community</h4>

                                            <p>
                                                We affirm that all people can direct their own supports and thereby encourage and promote true and meaningful participation in the community as essential to person-centered practices.
                                            </p>


                                            <h4 id='Employment'>Employment </h4>

                                            <p>
                                                Individuals of working age are employable: employment is life-enriching.
                                            </p>


                                            <h4 id='Direct Support Professionals'>Direct Support Professionals</h4>

                                            <p>
                                                We recognize and embrace Direct Support Professionals as essential team members whose input is valued and respected at all levels of planning and implementation of services pertinent to individuals they support.
                                            </p>


                                            <h4 id='Natural Supports'>Natural Supports</h4>

                                            <p>
                                                We promote and respect the involvement of family members and natural supports to empower individuals in the pursuit of active meaningful participation within their community.
                                            </p>


                                            <h4 id='Best Practice'>Best Practice</h4>

                                            <p>
                                                Initiatives and supports are driven by research and data to include the array of promising, evidence-based, and identified best practices.
                                            </p>

                                            <h1 id='DDID Branches'>DDID Branches</h1>

                                            <p>
                                                DDID comprises three branches:

                                                <br/>
                                                <br/>
                                                <Links linkTypeID="788" />
            
                                            </p>
                                            <br/>


                                        </div>
                                    </div>

                                    <div className="">
                                        <LayoutOne pageID="267" />

                                    </div>
                                </div>
                                {/* Related Links and other components */}
                                <div className='col'>
                                    <TableOfContent />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ScrollToTop />
            <Footer />
        </>
    );
}

export default DDIDHome;