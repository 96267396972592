import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import UnorderedList from "../../../layouts/bodyLayouts/helpers/UnorderedList";
import Links from "../../../components/Links";

const impactVideo_Content = [
    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "The History of Kentucky IMPACT"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            <>Below is a video describing the history of Kentucky IMPACT. Start or stop the video by clicking on the image. </>,
            <>
                <center >

                    <video width="520" height="340" controls>
                        <source style={{ alignContent: "center !important" }} src="https://dbhdid.ky.gov/dbh/videos/impact.jpg" type="video/mp4" />

                    </video>
                </center>
            </>,
            <>
                The control bar at the top of the image will let you pause or restart the video (arrow icon at the far left), control where you are in the video (slider to the right of the play/pause icon), control the volume, or toggle full-screen mode (the four-arrows icon at the far right)
            </>,
            <>
                You can also share the video on Facebook or Twitter using the share icon at the right of the image.
            </>


        ]
    }






];

export default impactVideo_Content;