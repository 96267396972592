import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const cSA_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Becoming an Approved Community Support Associate Training Provider"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "Becoming an approved Community Support Associate (CSA) training provider authorizes entities to teach the required training that allows individuals to be certified Community Support Associates in Kentucky.",
            "The following curricula are available for approval by the Department for Behavioral Health, Developmental and Intellectual Disabilities (DBHDID): 10-hour training curriculum for the Community Support Associate.",
            <>
                The Kentucky Administrative Regulations (KAR) specifically speak to the elements of a "training curriculum" and the training requirements (testing of the trainee and evaluation of the trainers), and provide the curriculum applicant with an understanding of the requirements for community support associates—both eligibility and training. These regulations can be found in the Related Links box on the right side of the page.
            </>,
            "DBHDID requires the use of specific documents to ensure a complete submission of all necessary materials. This will allow DBHDID staff to review the curricula in their entirety, and to make an approval decision or request supplementary materials in an efficient manner, within the period specified in the regulations. Curriculum submission requirements are listed below."
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "Submission Requirements for Community Support Associate 10-Hour Curriculum"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 40,
        content: [
            <>
                <ol>
                    <li>Review regulation 908 KAR 2:250 in Related Links.</li>
                    <li>Complete the <b> CSA Single Curriculum Submission Summary </b> PDF  Word This document contains identifying information about the applicant who is applying for curriculum approval.</li>
                    <li>Complete the <b> CSA 10-Hour Curriculum Rubric </b> PDF  Word This rubric identifies the required CSA core components and expectations for curriculum development. Please note that the curriculum cannot be reviewed if the rubric's yellow section is incomplete; the submitter's information will be returned and resubmission requested.</li>
                    <li>Submit an <b>electronic version</b> of the curriculum, saved as a Word, PowerPoint and/or PDF file using a USB flash drive. These documents must be clearly labeled with entities/submission name.</li>
                    <li>Include an <b>examination</b> and an <b>answer key</b> in your curriculum submission.</li>
                    <li>Include an <b>evaluation form</b> of the training.</li>
                </ol>
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 50,
        content: "Sample Curriculum Resources"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 60,
        content: [
            "Below are some sample resources to assist with curriculum development.",
            <><Links linkTypeID="754" /></>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 70,
        content: "Where to Submit Complete Curriculum and Supporting Documentation"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 80,
        content: [
            "Submit this information to:",
            <>
                Department for Behavioral Health, Developmental and Intellectual Disabilities <br />
                Division of Program Integrity <br />
                Program Support Branch <br />
                275 E. Main Street 4CD <br />
                Frankfort, KY 40621 <br />
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 90,
        content: "Curriculum Review"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 100,
        content: <>After a curriculum is received, it will be reviewed within 20 business days. Communication on the status of the submitted curriculum will be sent to the contact person listed on the "Single Curriculum Submission Summary."</>
    },
    {
        format: bodyHelperTypes.title,
        order: 110,
        content: "Approved Curriculum Guidelines"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 120,
        content: [
            "Once the curriculum is approved, the provider will establish and maintain a TRIS account by entering trainee information and uploading documentation directly into the system. Correspondence concerning the submission of this information will be sent via mail or email.",
            "After the curriculum is approved, when modifications occur, please notify the department using the “Contact Us” link in the Contact Information box at the top right of the page."
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 130,
        content: "Questions About the Process?"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 140,
        content: <>Please click on "Contact Us" in the Contact Information box to submit questions about the process.</>
    }
]
export default cSA_Content;