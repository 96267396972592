import { create } from 'zustand';
import currentPageStore from './currentPageStore';
import { get } from 'jquery';


const currentPageIDStore = create((set, get) => ({
    currentPageID: '',



    setCurrentPageID: (value) => set({ currentPageID: value })

   

})
);





export default { currentPageIDStore }
