import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import UnorderedList from "../../../layouts/bodyLayouts/helpers/UnorderedList";
import ContinuumOfCare from "../../../assets/img/sud/ContinuumOfCare.png"
import { Link } from "react-router-dom";
import Links from "../../../components/Links";

const ContinuumContent = [
    {
        format: bodyHelperTypes.image,
        order: 10,
        content: ContinuumOfCare




    },

    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "The Continuum of care describes the scope of behavioral health services for individuals before, during, and after they experience a behavioral health problem or disorder. It includes promotion, prevention, treatment, and maintenance.  As soon as someone begins treatment, they are in recovery and that continues beyond treatment and throughout the maintenance phase - potentially for a lifetime.  ",
            "The continuum segments the need for care and the type of care required from various parts of the health care system. This means there are multiple opportunities for addressing behavioral health problems.  ",
            "The continuum underscores the interrelationship among promotion, prevention, treatment, and maintenance. While some service may be more specific, individualized, or costly than others, it shows that each phase along the continuum does not exist in isolation.",
            <><b>By forming strategic partnerships throughout state government and creating an interconnected system, agencies are better equipped to serve Kentuckians with behavioral health needs such as mental illness and/or substance use disorders. Through collaboration, agencies can leverage resources and promote wellness so that all citizens can thrive. </b></>

        ]




    },
    {
        format: bodyHelperTypes.paragraph,
        order: 30,
        content: [
            <>
                The Division of Substance Use Disorder proudly collaborates with the following branches/programs within DBHDID:<br /><br/>

                <UnorderedList items={
                    [
                        "Kentucky MOMS MATR",
                        "Substance Use Disorder Treatment Branch",
                        "Mental Health Promotion, Prevention, and Preparedness Branch",
                        "Children's Branch"
                    ]
                } />
            </>,
            <>
            The Division collaborates with various other state government agencies:
            <br/>
            <br/>
            <Links linkTypeID="774" />

            </>,
            <>
             The Division collaborates with various partners across the commonwealth:
            <br/>
            <br/>
            <Links linkTypeID="784" />
               
            </>,
            <>
             In addition, the Division collaborates with several national partners:
            <br/>
            <br/>
            <Links linkTypeID="785" />
               
            </>
        ]




    },
];

export default ContinuumContent;