import { isNullOrWhiteSpace } from "../utils/StringUtils";


function IsNullOrWhiteSpace({ arg, isNullComponent, notNullComponent }) {

    if  (isNullComponent == undefined) {
     isNullComponent  = null;
    }
    const component = notNullComponent == null ?
        <span>{arg}</span> :
        notNullComponent; 
    return isNullOrWhiteSpace(arg) ? isNullComponent : component ;



}


export default IsNullOrWhiteSpace;