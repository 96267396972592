//Using the react library
import React from "react";
//Using the react library:
import { useEffect, useState } from "react";
import useApi from "../utils/UseAPI";
import SanitizeHref from "../utils/SanitizeHref";

function DocumentsDropdown({typeId, ...props}) {

    const { error, processing, getRequest } = useApi();
    const [documentsDocs, setDocumentDocs] = useState([]);

    useEffect(() => {
        const fetchLinks = async () => {
            const [data, status] = await getRequest(null, "document/?documentTypeId=" + typeId);
            if (status == 200) {
                // console.log(data.data);
                setDocumentDocs(data.data);
            }
            else {
                console.log(error);
            }
        };
        fetchLinks();
    }, []);
    return (
        <>
            <div className="col-md-4 col-md-3">
                {/* Return List */}
                <select className="form-select">
                    <option>Select a meeting date</option>        
                    {
                        documentsDocs.map((data, index) => {
                            return (
                                <option key={index}  value={data.typeID} className="form-select"
                                ><a href={SanitizeHref(data.url)}>{data.name}</a></option>
                            )
                        })
                    }
                </select>
            </div>
        </>
    );
}

export default DocumentsDropdown;