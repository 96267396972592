import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import UnorderedList from "../../../layouts/bodyLayouts/helpers/UnorderedList";
import PESLogo from "../../../assets/img/sud/PESLogo.png"
import Picture from "../../../layouts/bodyLayouts/helpers/Picture";
import Links from "../../../components/Links";

const eBPIInterviewing = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Motivational Interviewing"



    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "Motivational Interviewing (MI) is a person-centered, goal-directed method for engagement and enhancing intrinsic motivation to change behavior by exploring and resolving ambivalence. Resolving ambivalence will increase an individual's engagement in treatment and enhance his or her ability to stay and complete treatment and to sustain the changes.",
            "Motivational Interviewing is not a technique or a set of techniques that is applied to people; rather, it is an interpersonal style, not at all restricted to formal counseling settings.",
            "The clinician practices motivational interviewing with five general principles in mind:",
            <><UnorderedList items={[
                "Express empathy through reflective listening.",
                "Develop discrepancy between clients' goals or values and their current behavior.",
                "Avoid argument and direct confrontation.",
                "Adjust to client resistance rather than opposing it directly.",
                "Support self-efficacy and optimism."
            ]} /></>,
            "Motivational Interviewing is a complex, advanced skill that requires consistent personal monitoring to remain faithful to the model.",
            "Use the Contact Information to obtain further information."
        ]



    },

    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "Links"

    },
    {
        format: bodyHelperTypes.links,
        order: 40,
        content: <Links linkTypeID="165" />

    },


];

export default eBPIInterviewing;