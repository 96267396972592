// Libs:
import React from 'react';
// css:
import "../../assets/styles/pages/style.css";
// Header
import DepartmentHeader from '../../components/DepartmentHeader';
// Layout
import LayoutOne from '../../layouts/bodyLayouts/LayoutOne';

import Links from '../../components/Links';
import { Link } from 'react-router-dom';
import Navbar from '../../layouts/Navbar';
import Breadcrumbs from '../../components/buttons/Breadcrumbs';
import ScrollToTop from '../../components/ScrollToTop';
import Footer from '../../layouts/Footer';
import TableOfContent from '../../components/TableOfContent';



function SCLRegulations() {
    return (
        <>
            <Navbar iD="361" />
            <Breadcrumbs />
            <div className="body outerContainer bodyRow main-body">
                <div className="mainContentArea mainContent" id="bodyContent">
                    <div>
                        <div className='container body'>
                            <div className="row">
                                <div className=" col-9">
                                    <DepartmentHeader header="Supports for Community Living Waiver Branch" />
                                    <div class="">
                                        <div className="">
                                            <div className='bodyText'>
                                                <div className="subTitle">
                                                    <h1 id='SCL and MPW Regulations'>SCL and MPW Regulations</h1>
                                                    <p>

                                                        <Links linkTypeID="310" />
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <LayoutOne pageID="361" />
                                        </div>
                                    </div>
                                </div>
                                <div className='col'>
                                    <TableOfContent />
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ScrollToTop />
            <Footer />
        </>
    );
};
export default SCLRegulations;