import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const eBPIRecovery_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Recovery"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            <>
                Recovery is a process of change through which individuals improve their health and wellness, live self-directed lives, and strive to reach their full potential.
            </>,
            "This definition does not describe recovery as an end state, but rather as a process. Complete symptom remission is neither a prerequisite of recovery nor a necessary outcome of the process. Recovery can have many pathways that may include professional clinical treatment; use of medications; support from families and in schools; faith-based approaches; peer support; and other approaches. There are four major dimensions that support a life in recovery:",
            "A process of change through which individuals improve their health and wellness, live a self-directed life, and strive to reach their full potential.",
            <>
                <ul>
                    <li><b>Health</b></li>
                    <li><b>Home</b></li>
                    <li><b>Purpose</b></li>
                    <li><b>Community</b></li>
                </ul>
            </>,
            <>
                In addition, there are 10 Principles of Recovery:
                <br />
                <br />
                <ol>
                    <li>Recovery emerges from hope.</li>
                    <li>Recovery is person driven.</li>
                    <li>Recovery occurs via many pathways.</li>
                    <li>Recovery is holistic.</li>
                    <li>Recovery is supported by peers and allies.</li>
                    <li>Recovery is supported through relationship and social networks.</li>
                    <li>Recovery is culturally-based and influenced.</li>
                    <li>Recovery is supported by addressing trauma.</li>
                    <li>Recovery involves individual, community strengths, and responsibility.</li>
                    <li>Recovery is based on respect.</li>
                </ol>
            </>,
            <>Click "SAMHSA Recovery" in the Related Links box to find out more about the four dimensions and the 10 Principles of Recovery.</>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "Peer Support"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 40,
        content: [
            "The President's New Freedom Commission Report (2003) also outlines a plan to transform mental health care in America to promote a recovery-oriented service system. Peer support is an important element in this transformation process.",
            "The Peer Specialist Service is a structured and scheduled therapeutic activity with an individual client or group, provided by a trained, self-identified consumer of mental health or substance use services. A Peer Specialist guides clients toward the identifying and achieving of specific goals defined by the client and specified in the Treatment Plan.",
            <><b>How to Become a Peer Support Specialist</b></>,
            "To become qualified, a Kentucky Peer Specialist (KPS) completes 30 hours of training and passes both a written and oral test. The job of a KPS is not to replace current clinical mental health staff but to offer additional and/or alternative options to help people in their efforts to recover.",
            "Regular supervision is required, and each qualified Kentucky Peer Specialist must earn at least six hours of continuing education each year. To locate a training event in Kentucky, access the following links by the type of Peer Specialist.",
            <><Links linkTypeID="633" /></>,
            <><b>How to Become a Trainer for Peer Support Curricula</b></>,
            "The Department supports consumer-driven recovery-oriented services. DBHDID approves training curricula for providers that request to become trainers for the Peer Specialist types listed above.",
            <><b>For More Information</b></>,
            "The Kentucky regulation for peer support are listed below, as well as additional information about this service and the required training and supervision requirements (see Related Links)."

        ]
    },    
]
export default eBPIRecovery_Content;