import { isNullOrWhiteSpace } from "./StringUtils";

const breadCrumbMap = new Map([
    ['mh','Mental Health'],
    ['ddid', 'Developmental and Intellectual Disabilities'],
    ['afm', 'Administration and Financial Management'],
    ['sud','Substance Use Disorder'],
    ['cmhc','Community Mental Health Centers'],
    ['dpi', 'Program Integrity']
]);


const getBreadCrumb = (key) => {

    if(isNullOrWhiteSpace(key)){

        return key;

    }

    key = key.toLowerCase();
    
    if (breadCrumbMap.has(key)) {

        return breadCrumbMap.get(key);
    }
    else {
        return key;
    }

};


export default getBreadCrumb;