import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";


const pI_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Performance Indicator Implementation Guides"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            <><Links linkTypeID="736" /></>
        ]
    },
];

export default pI_Content;