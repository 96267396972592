import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import DPRForms from "../../../components/DPRForms";

const planAndBudget_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Plan and Budget Information - FY 2025"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            <><DPRForms /></>
        ]
    },
];

export default planAndBudget_Content;