//Using the react library
import React from "react";
//Using the react library:
import { useEffect, useState } from "react";
//API Middle man
import useApi from "../utils/UseAPI";
// React loading spinner
import Spinner from 'react-bootstrap/Spinner';
// Check if Contact info is null




function JCICList() {

    const { error, processing, getRequest } = useApi();
    const [contentCounties, setContentCounties] = useState([]);
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        const fetchLinks = async () => {
            const [data, status] = await getRequest(null, "cmhc/documents");
            if (status == 200) {
                setContentCounties(data.data)
                console.log(data.data)
            }
            else {
                console.log(error)
            }
        };
        fetchLinks();
    }, []);

    return (
        <>
            <div className="col-md-4 col-md-3">
                {/* Return List */}
                <select className="form-select" onChange={(e) => {
                    // console.log(e.target.value);
                    // console.log(contentCounties);
                    const c = contentCounties?.find((x) => x.id == e.target.value)

                    setSelected(c)
                    console.log(selected)
                }}>
                    <option>Select a meeting date </option>
                    {
                        processing ? <Spinner animation="border" variant="success" /> : contentCounties.map((data, index) => {
                            return (
                                <option key={index} value={data.id} className="form-select"
                                >{data.description}</option>
                            )
                        })
                    }
                </select>

            </div>
            {selected ?
                <>

                    <b>Counties Served: </b> {selected.counties.map((data) => {
                        return (
                            <div>
                                {data.name}
                            </div>
                        )
                    })
                    }
                    <br />
                    <a href={selected.web}>Center Website</a>
                    <br />
                    <br />
                    {selected.contacts.map((data) => {
                        return (
                            <div>
                                <b>{data.title}</b><br />
                                {data.name}<br />
                                {data.address1}<br />
                                {data.city}, {data.state} {data.zip}<br />
                                Phone: {data.phone}
                                <br />
                                <br />


                            </div>
                        )
                    })
                    }

                </>



                : null}
        </>

    );
}

export default JCICList;