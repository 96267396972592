import React from "react";


import "../assets/styles/pages/style.css";


function DepartmentHeader(props) {
    return (
        <>
        
         <div className="">
                <div >
                <h1 id={props.header} className=" main-header-layout">{props.header}<div className="green-underline"></div></h1>
                </div>
               
            </div>



        </>

    );
}

export default DepartmentHeader;