import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const tCM_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Becoming an Approved Targeted Case Management Training Provider"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "Becoming an approved Targeted Case Management (TCM) training provider authorizes entities to teach the required training that allows individuals to be certified Approved Targeted Case Management in Kentucky.",
            "The following curricula are available for approval by the Department for Behavioral Health, Developmental and Intellectual Disabilities (DBHDID):",
            <>
                <ul>

                    <li>Targeted Case Management 12-Hour Core (TCM Core)</li>
                    <li>Serious Mental Illness (SMI)</li>
                    <li>Severe Emotional Disability (SED)</li>
                    <li>Substance Use Disorder (SUD)</li>
                    <li>Co-occurring Behavioral Health (SMI, SED, SUD) and Chronic or Complex Physical Health Condition(s) (CCPHC)</li>

                </ul>
            </>,
            <>
                The Kentucky Administrative Regulations (KAR) specifically speak to the elements of a "training curriculum" and the training requirements (testing of the trainee and evaluation of the trainers), and provide the curriculum applicant with an understanding of the requirements for targeted case management—both eligibility and training. These regulations can be found in the Related Links box on the right side of the page.
            </>,
            <>
                DBHDID requires the use of specific documents to ensure, the complete submission of all necessary materials. This will allow DBHDID staff to review the curricula in their entirety and make an approval decision or request supplementary materials efficiently within the period specified in the regulations. Curriculum submission requirements are listed below.
            </>,
            "For a list of Frequently Asked Questions, please see the Related Links box on the right side of the page."
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "Submission Requirements for Targeted Case Management 12-Hour Curriculum"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 40,
        content: <>
            <ol>
                <li>Review regulation 908 KAR 2:260 in Related Links.</li>
                <li>Complete the Single Curriculum Submission Summary PDF Word. This document contains identifying information about the applicant applying for curriculum approval.</li>
                <li>Complete the TCM 12-Hour Core Curriculum Rubric  PDF  Word. This rubric identifies the required TCM core components and expectations for curriculum development. Please note that the curriculum cannot be reviewed if the rubric's yellow section is incomplete; the submitter's information will be returned, and resubmission is  requested.</li>
                <li>Submit an electronic version of the curriculum, saved as a Word, PowerPoint and/or PDF file using a USB flash drive. These documents must be clearly labeled with entities/submission name.</li>
                <li>Include an examination and an answer key in your curriculum submission.</li>
                <li>Include an evaluation form of the training.</li>
            </ol>

        </>
    },
    {
        format: bodyHelperTypes.title,
        order: 50,
        content: "Submission Requirements for Family Peer Support Specialist 30-Hour Curriculum"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 60,
        content: <>
            <ol>
                <li>Review regulation 908 KAR 2:260 in Related Links.</li>
                <li>Complete the Single Curriculum Submission Summary PDF Word. This document contains identifying information about the applicant applying for curriculum approval.</li>
                <li>Complete the TCM 12-Hour Core Curriculum Rubric  PDF  Word. This rubric identifies the required TCM core components and expectations for curriculum development. Please note that the curriculum cannot be reviewed if the rubric's yellow section is incomplete; the submitter's information will be returned, and resubmission requested.</li>
                <li>Submit an electronic version of the curriculum, saved as a Word, PowerPoint, and/or PDF file on a USB flash drive. The documents must be clearly labeled with the entity/submission name.</li>
                <li>Include an examination and an answer key in your curriculum submission.</li>
                <li>Include an evaluation form of the training.</li>
            </ol>
        </>
    },
    {
        format: bodyHelperTypes.title,
        order: 70,
        content: "Submission Requirements for Severe Mental Illness 6-Hour Curriculum"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 80,
        content: <>
            <ol>
                <li>Review regulation 908 KAR 2:260 in Related Links.</li>
                <li>Complete the Single Curriculum Submission Summary PDF Word. This document contains identifying information about the applicant applying for curriculum approval.</li>
                <li>Complete the SMI 6-Hour Curriculum Rubric  PDF  Word
                    This rubric identifies the required SMI core components and expectations for curriculum development. Please note that the curriculum cannot be reviewed if the rubric's yellow section is incomplete; the submitter's information will be returned, and resubmission is requested.</li>
                <li>Submit an electronic version of the curriculum, saved as a Word, PowerPoint and/or PDF file using a USB flash drive. These documents must be clearly labeled with entities/submission name.</li>
                <li>Include an examination and an answer key in your curriculum submission.</li>
                <li>Include an evaluation form of the training.</li>
            </ol>
        </>
    },
    {
        format: bodyHelperTypes.title,
        order: 90,
        content: "Submission Requirements for Severe Emotional Disability 6-Hour Curriculum"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 100,
        content: <>
            <ol>

                <li>Review regulation 908 KAR 2:260 in Related Links.</li>
                <li>Complete the Single Curriculum Submission Summary PDF Word. This document contains identifying information about the applicant applying for curriculum approval.</li>
                <li>Complete the SED 6-Hour Curriculum Rubric  PDF  Word
                    This rubric identifies the required SED core components and expectations for curriculum development. Please note that the curriculum cannot be reviewed if the rubric's yellow section is incomplete; the submitter's information will be returned, and resubmission requested.</li>
                <li>Submit an electronic version of the curriculum, saved as a Word, PowerPoint, and/or PDF file on a USB flash drive. The documents must be clearly labeled with the entity/submission name.</li>
                <li>Include an examination and an answer key in your curriculum submission.</li>
                <li>Include an evaluation form of the training.</li>
            </ol>
        </>
    },
    {
        format: bodyHelperTypes.title,
        order: 110,
        content: "Submission Requirements for Substance Use Disorder 6-Hour Curriculum"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 120,
        content: <>
            <ol>
                <li>Review regulation 908 KAR 2:260 in Related Links.</li>
                <li>Complete the Single Curriculum Submission Summary PDF Word. This document contains identifying information about the applicant applying for curriculum approval.</li>
                <li>Complete the SUD 6-Hour Curriculum Rubric PDF Word.
                    This rubric identifies the required SUD core components and expectations for curriculum development. Please note that the curriculum cannot be reviewed if the rubric's yellow section is incomplete; the submitter's information will be returned, and resubmission is requested.</li>
                <li>Submit an electronic version of the curriculum, saved as a Word, PowerPoint and/or PDF file using a USB flash drive. These documents must be clearly labeled with entities/submission name.</li>
                <li>Include an examination and an answer key in your curriculum submission.</li>
                <li>Include an evaluation form of the training.</li>
            </ol>
        </>
    },
    {
        format: bodyHelperTypes.title,
        order: 130,
        content: "Submission Requirements for Co-Occurring Behavioral Health (Serious Mental Illness, Severe Emotional Disability, Substance Use Disorder) and Chronic or Complex Physical Health Condition(s) 6-Hour Curriculum"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 140,
        content: <>
            <ol>
                <li>Review regulation 908 KAR 2:260 in Related Links.</li>
                <li>Complete the Single Curriculum Submission Summary PDF Word. This document contains identifying information about the applicant applying for curriculum approval.</li>
                <li>Complete the SMI, SED, or SUD and a Co-occurring Chronic or Complex Physical Health Condition 6-Hour Curriculum Rubric  PDF  Word
                    This rubric identifies the required CCPHC core components and expectations for curriculum development. Please note that the curriculum cannot be reviewed if the rubric's yellow section is incomplete; the submitter's information will be returned, and resubmission requested.</li>
                <li>Submit an electronic version of the curriculum, saved as a Word, PowerPoint and/or PDF file using a USB flash drive. These documents must be clearly labeled with entities/submission name.</li>
                <li>Include an examination and an answer key in your curriculum submission.</li>
                <li>Include an evaluation form of the training.</li>
            </ol>
        </>
    },
    {
        format: bodyHelperTypes.title,
        order: 150,
        content: "Sample Curriculum Resources"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 160,
        content: <>
            <ol>
                <li><a>Sample curriculum</a></li>
                <li><a>Sample evaluation of training</a></li>
                <li><a>Sample trainee test questions</a></li>
            </ol>
        </>
    },
    {
        format: bodyHelperTypes.title,
        order: 170,
        content: "Where to Submit Complete Curriculum and Supporting Documentation"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 180,
        content: [
            "Submit this information to:",
            <>
                Department for Behavioral Health, Developmental and Intellectual Disabilities <br />
                Division of Program Integrity <br />
                Program Support Branch <br />
                275 E. Main Street 4CD <br />
                Frankfort, KY 40621 <br />
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 190,
        content: "Curriculum Review and Trainer Requirements"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 200,
        content: [
            <>After a curriculum is received, it will be reviewed within 20 business days. Communication on the status of the submitted curriculum will be sent to the contact person listed on the "Single Curriculum Submission Summary."</>,
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 190,
        content: "Approved Curriculum Guidelines"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 200,
        content: [
            <>Once the curriculum is approved, the provider will establish and maintain a TRIS account by entering trainee information and uploading documentation directly into the system. Correspondence concerning the submission of this information will be sent via mail or email.</>,
            "After the curriculum is approved, when modifications occur, please notify the department using the “Contact Us” link in the Contact Information box at the top right of the page."
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 210,
        content: "Questions About the Process?"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 220,
        content: [
            <>Please email us at <a href='csa@ky.gov'>csa@ky.gov</a></>,
            
        ]
    }

];

export default tCM_Content;