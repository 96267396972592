import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import { Link } from "react-router-dom";
import Links from "../../../components/Links";



const kCCRT_Content = [
    
    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Kentucky Community Crisis Response Team (KCCRT)"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            "The Kentucky Community Crisis Response Team’s (KCCRT) is a free, confidential State resource that provides leadership, education, training, consultation, and support services to prepare and support the Commonwealth to respond to and recover from critical incidents and disasters. ",
            "KCCRT services are available to support first responders, schools, industry/businesses, and communities. ",
            "An agency may consider contacting KCCRT when the local resources are limited or exhausted and are unable to meet the assessed need for services. KCCRT works collaboratively with the agency to assess the need for a crisis response team and assist in identifying the local capabilities and any gap in desired services.",
            "KCCRT’s role in any event is at the direction of the agency that is requesting services.",
            <>You can find more information about KCCRT at <a href="https://kccrt.ky.gov/">kccrt.ky.gov</a> and through viewing the documents below:</>, 
            <><Links linkTypeID={776}/></>
        ]
    },
    
    






];

export default kCCRT_Content;