import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";

import Links from "../../../components/Links";

const recordingsContent = [
    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            <><Links linkTypeID="804" /></>
        ]
    },

    {
        format: bodyHelperTypes.title,
        order: 20,
        content: "Dream Weavers Series – March 2024 "
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 30,
        content: [
            <><Links linkTypeID="802" /></>
        ]
    },
    
    {
        format: bodyHelperTypes.title,
        order: 40,
        content: "Medicaid Eligibility for Waiver Services -  April 18, 2024"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 50,
        content: [
            <><Links linkTypeID="803" /></>
        ]
    },
    
    


];

export default recordingsContent;