// Libs:
import React from 'react';
// css:
import "../assets/styles/pages/style.css";
// Header
import DepartmentHeader from '../components/DepartmentHeader';
// Layout
import LayoutOne from '../layouts/bodyLayouts/LayoutOne';
import Links from '../components/Links';

import Navbar from '../layouts/Navbar';
import Breadcrumbs from '../components/buttons/Breadcrumbs';
import Footer from '../layouts/Footer';
import TableOfContent from '../components/TableOfContent';
import ScrollToTop from '../components/ScrollToTop';

function PASRRForms() {
    return (
        <>

            <Navbar iD={"288"} />
            <Breadcrumbs />
            <div className="body outerContainer bodyRow main-body">
                <div className="mainContentArea mainContent" id="bodyContent">
                    <div>
                        <div className='container body'>

                            <div className="row">
                                <div className=" col-9">
                                    <DepartmentHeader header="Preadmission Screening and Resident Review" />
                                    <div class="">
                                        <div className="">

                                            <div className='bodyText'>
                                                <div className="subTitle">

                                                    <h1 id='PASRR Forms and Resources'>PASRR Forms and Resources</h1>

                                                    <p>
                                                        Click on the name of a document in the list below to access a copy of the document.

                                                        

                                                        <Links linkTypeID="227" />
                                                    </p>

                                                    <h1 id='PASRR Regulations and Statutes'>PASRR Regulations and Statutes</h1>

                                                    <p>
                                                        <Links linkTypeID="769" />
                                                    </p>



                                                    <h1 id='DMS Provider Forms'>DMS Provider Forms</h1>

                                                    <p>
                                                        For the MAP-409, MAP-4092, MAP-4093, MAP-4094 AND MAP-4095, please visit the first link below and choose "Provider" from the "Forms" dropdown list near the top of the page, then scroll down to the MAP forms.

                                                        <br/>
                                                        <br/>

                                                        <Links linkTypeID="717" />
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <LayoutOne pageID="288" />
                                        </div>
                                    </div>
                                </div>

                                <div className='col'>

                                    <TableOfContent />
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ScrollToTop />

            <Footer />
        </>
    );
};
export default PASRRForms;