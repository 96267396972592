import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import { Link } from "react-router-dom";

const cAP_Content = [


    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            "The links below provide information about curriculum approval processes outlined in Title 908, Chapter 2 of the Kentucky Administrative Regulations, which authorizes entities to teach the required training that allows individuals to become certified Community Support Associates, Peer Support Specialists, and Targeted Case Managers in Kentucky",
            <>
                Click on a link below for more information about that area of interest.
                <br/>
                <br/>
                <ul className="no-bullet">
                    <li>
                        <Link className="quick-links-body" to={"/mh/csa"}>
                            <div>Community Support Associate Training Provider Curriculum Approval Process</div>
                        </Link>
                    </li>
                    <li>
                        <Link className="quick-links-body" to={"/mh/pss"}>
                            <div>Peer Support Specialists Training Provider Curriculum Approval Process</div>
                        </Link>
                    </li>
                    <li>
                        <Link className="quick-links-body" to={"/mh/tcm"}>
                            <div>Targeted Case Management Training Provider Curriculum Approval Process</div>
                        </Link>
                    </li>
                    <li>
                        <Link className="quick-links-body" to={"/dpi/tris"}>
                            <div>DBHDID Training Records Information System</div>
                        </Link>
                    </li>
                </ul>
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 20,
        content: "Certification Requirements"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 30,
        content: [
            <>
                <ul className="no-bullet">

                    <li>
                    <h4 id='How to Become a Community Support Associate (CSA) in Kentucky'>How to Become a Community Support Associate (CSA) in Kentucky</h4>
                        <a className="quick-links-body" href="https://apps.legislature.ky.gov/law/kar/titles/908/002/250/">Review regulation 908 KAR 2:220, Section 2.</a>
                    </li>

                    <li>
                        If requesting a waiver for reciprocity purposes, please review regulation 908 KAR 2:220, Section 5.
                        Waivers can be requested through the “Contact Us” link in the Contact Information box at the top right of the page.
                    </li>
                </ul>
            </>,
            <>
                To become a Family Peer Support Specialist, an individual must complete the initial 30-hour training with an approved training provider
            </>,
            <>
                For questions regarding the schedule of training, cost of training, etc., please contact your nearest <b>approved training provider</b> from the "PSS Training Providers: Family" link in Related Links.
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 40,
        content: "How to Become a Peer Support Specialist (PSS) in Kentucky"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 50,
        content: [
            <>
                <ul className="no-bullet">
                
                        <li>
<h4 id="Adult Peer Support Specialist (APSS)">Adult Peer Support Specialist (APSS)</h4>
                            <a className="quick-links-body" href="https://apps.legislature.ky.gov/law/kar/titles/908/002/220//">Review regulation 908 KAR 2:220, Section 2.</a>
                        </li>
                        <li>
                            If requesting a waiver for reciprocity purposes, please review regulation 908 KAR 2:220, Section 5. Waivers can be requested through the “Contact Us” link in the Contact Information box at the top right of the page.
                        </li>
                  
                </ul>
            </>,
            <>
                To become an Adult Peer Support Specialist, an individual must complete the initial 30-hour training with an approved training provider.
            </>,
            <>
                For questions regarding the schedule of training, cost of training, etc., please contact your nearest <b>approved training provider</b> from the "PSS Training Providers: Adult" link in Related Links.
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 60,
        content: "Family Peer Support Specialist (FPSS)"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 70,
        content: [
            <>
                <ul className="no-bullet">
                    <li>
                        <a className="quick-links-body" href="https://apps.legislature.ky.gov/law/kar/titles/908/002/230/">Review regulation 908 KAR 2:230, Section 2.</a>
                    </li>
                    <li>
                        Review regulation 908 KAR 2:230, Section 2.
                        If requesting a waiver for reciprocity purposes, please review regulation 908 KAR 2:230, Section 5.
                        Waivers can be requested through the “Contact Us” link in the Contact Information box at the top right of the page.
                    </li>
                </ul>
            </>,
            <>
                To become a Family Peer Support Specialist, an individual must complete the initial 30-hour training with an approved training provider.
            </>,
            <>
                For questions regarding the schedule of training, cost of training, etc., please contact your nearest <b>approved training provider</b> from the "PSS Training Providers: Family" link in Related Links.
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 80,
        content: "Youth Peer Support Specialist (YPSS)"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 90,
        content: [
            <>
                <ul className="no-bullet">

                    <li>
                        <a className="quick-links-body" href="https://apps.legislature.ky.gov/law/kar/titles/908/002/240/">Review regulation 908 KAR 2:240, Section 2 and Section 5.</a>
                    </li>

                    <li>
                        If requesting a waiver for reciprocity purposes, please review regulation 908 KAR 2:240, Section 6.
                        Waivers can be requested through the “Contact Us” link in the Contact Information box at the top right of the page.
                    </li>
                </ul>
            </>,
            <>
                To become a Youth Peer Support Specialist, an individual must complete the initial 30-hour training with an approved training provider.
            </>,
            <>
                For questions regarding the schedule of training, cost of training, etc., please contact your nearest <b>approved training provider</b> from the "PSS Training Providers: Family" link in Related Links.
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 100,
        content: "Alcohol and Other Drug Entities Continuing Education Requirements for Peer Support Specialists"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 110,
        content: [
            <>
                Pursuant to 908 KAR 2:220, 908 KAR 2:230, and 908 KAR 2:240, all peer support specialists are required to complete only 6 hours of continuing education annually.
            </>,
            <>
                If the peer support specialist falls under 908 KAR 1:370—alcohol and other drug entities—then the peer support specialist is required to complete only 10 hours of continuing education in alcohol and other drug-relevant training annually.
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 120,
        content: "How to Become a Targeted Case Manager (TCM) in Kentucky"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 130,
        content: [
            <>
                <ul className="no-bullet">

                    <li>
                        <a className="quick-links-body" href="https://apps.legislature.ky.gov/law/kar/titles/908/002/260/">Review regulation 908 KAR 2:260, Section 2.</a>
                    </li>

                </ul>
                To become a Targeted Case Manager, an individual must complete the initial 12-hour training plus the additional 6-hour specialty training with an approved training provider.
            </>,
            <>
                For questions regarding the schedule of training, cost of training, etc., please contact your nearest approved training provider from the <b>"TCM Training Providers" </b>link in Related Links.
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 140,
        content: "Alcohol and Other Drug Entities Continuing Education Requirements for Targeted Case Managers"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 150,
        content: [
            "Pursuant to 908 KAR 2:260, all targeted case managers are required to complete only 6 hours of continuing education annually.",
            "If the peer support specialist falls under 908 KAR 1:370—alcohol and other drug entities—then the peer support specialist is required to complete only 10 hours of continuing education in alcohol and other drug-relevant training annually."
        ]
    }
];

export default cAP_Content;