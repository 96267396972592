import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import { Link } from "react-router-dom";
import Links from "../../../components/Links";
import UnorderedList from "../../../layouts/bodyLayouts/helpers/UnorderedList";



const adultMHCJI_Content = [
    
    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Criminal Justice Interface"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "In July 2004 Kentucky recognized the importance of keeping individuals who have a mental illness out of the criminal justice system by passing the Decriminalization of mental illness regulation (908 KAR 2:090). This regulation mandated that those who are mentally ill are not be subject to detention while awaiting a mental health evaluation for an involuntary hospitalization (KRS 202A).",
            "The Department for Behavioral Health, Developmental and Intellectual Disabilities has continued to place emphasis on collaborating with the criminal justice system to help to identify—and when possible, divert—persons with mental illness away from detention centers and into treatment.",
            "The Department maintains several programs that seek to identify, divert and treat those who have a mental illness at different levels of the criminal justice system. These programs are described below."
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content:  "Crisis Intervention Teams (CIT)"
        

    },
    {
        format: bodyHelperTypes.paragraph,
        order:40,
        content:"Created by legislation in June 2007, this program a 40-hour in-service training for police officers. The training is offered 10 times per year around the state and is Kentucky Law Enforcement Counsel (KLEC)-approved. The training helps law enforcement identify and support persons in a mental health crisis who may otherwise be taken to a detention facility."
    },
    {
        format: bodyHelperTypes.title,
        order:50,
        content:"Behavioral Health Jail Triage System"
    },
    {
        format: bodyHelperTypes.paragraph,
        order:60,
        content:"Created by legislation in June 2007, this system provides telephonic behavioral health screening for prisoners who exhibit signs of mental illness. The assessment is designed to address protocols for housing, supervision, and treatment to help mitigate the mental health risks identified by the system. This system is available for use by all county detention centers."
    },
    {
        format: bodyHelperTypes.title,
        order:70,
        content:"Reintegration"
    },
    {
        format: bodyHelperTypes.paragraph,
        order:80,
        content:"This program works within three prisons in Kentucky: the Kentucky State Reformatory, Luther Luckett Correctional Complex, and the Kentucky Correctional Institute for Women. It is designed to assist individuals with mental illnesses in transitioning back to the community from prison. Staffed by two case managers and a peer support specialist, the program begins working with individuals months before release to ensure that they have housing, treatment appointments, benefits, benefits and other community supports in place before being released from prison."
    },
    // {
    //     format: bodyHelperTypes.title,
    //     order:90,
    //     content:"Forensic Assertive Community Treatment (FACT)"
    // },
    // {
    //     format: bodyHelperTypes.paragraph,
    //     order:100,
    //     content:"This program was created in 2016 and provides wrap-around services in the community to keep individuals who have a mental illness thriving in the community. Much like a traditional Assertive Community Treatment (ACT) team, the FACT team primary focus is persons who have a severe mental illness and frequent contact with the criminal justice system. Currently this program is operated in Louisville by Centerstone."
    // }
    
   
    
    






];

export default adultMHCJI_Content;